import React from "react";
import VerificationInput from "react-verification-input";

const VerificationForm = ({
  label,
  code,
  setCode,
  onSubmit,
  isButtonDisabled,
  buttonText,
}) => {
  return (
    <form onSubmit={onSubmit} className="space-y-8">
      <div className="flex flex-col items-center">
        <label className="text-white text-lg mb-4">{label}</label>
        <VerificationInput
          value={code}
          onChange={setCode}
          length={6}
          placeholder=""
          classNames={{
            container: "container",
            character: "bg-white text-black rounded p-2 mx-1 text-xl w-10 h-12",
            characterInactive: "character--inactive",
            characterSelected: "border-2 border-blue-500",
          }}
        />
      </div>
      <button
        type="submit"
        className="w-full bg-[#b5924e]  text-white py-2 hover:bg-yellow-600 rounded-lg transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
        disabled={isButtonDisabled}
      >
        {buttonText}
      </button>
    </form>
  );
};

export default VerificationForm;
