import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { userState } from "../atoms/atoms";
import { useRecoilValue } from "recoil";
const Congratulation = () => {
  const navigate = useNavigate();
  const user = useRecoilValue(userState);

  if (!user?.email) {
    navigate("/");
  }

  return (
    <>
      <div className="container mx-auto flex flex-col items-center ">
        <div className="bg-gray-800 p-8 rounded-lg shadow-lg w-full max-w-xl">
          <div>
            <h1 className="text-3xl font-bold text-green-500 mb-8 ">
              Congratulations! 🎉
            </h1>
            <p className="congratulations-message text-white">
              You have successfully completed the onboarding process.
            </p>
            <p className="congratulations-message text-white mt-3">
              We are now reviewing the information you provided as part of our
              due diligence process. This usually takes 2-3 business days.
            </p>
            <p className="congratulations-message text-white mt-3">
              If you have any questions in the meantime, feel free to reach out
              to us at info@sovera.com. We're here to assist you!
            </p>
            <p className="congratulations-message text-white mt-3">
              Thank you for choosing Sovera! 😊
            </p>

            <Link
              to="/"
              className="mt-4 inline-block  text-center  rounded-full bg-yellow-600 text-white font-bold py-2 px-4  hover:bg-green-700 transition duration-300"
            >
              Go to Home
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default Congratulation;
