import * as Yup from "yup";

export const individualFormSchema = Yup.object().shape({
  account_type: Yup.string().required("Account type is required"),
  account_role: Yup.string().required("Account role is required"),
  account_purpose: Yup.string().required("Account purpose is required"),
  first_name: Yup.string()
    .required("First name is required")
    .matches(/^[^\s]+(\s+[^\s]+)*/, "Characters must be alphanumaric"),
  last_name: Yup.string()
    .required("Last name is required")
    .matches(/^[^\s]+(\s+[^\s]+)*/, "Characters must be alphanumaric"),
  email: Yup.string().email("Invalid email").required("Email is required"),
  phone_country_code: Yup.string().required("Country code is required"),
  phone_number: Yup.string()
    .required("Phone number is required")
    .matches(/^[0-9]+$/, "Phone number must only contain digits"),
  individual: Yup.object().shape({
    dob: Yup.string()
      .required("Date of birth is required")
      .test("dob", "You must be at least 18 years old", (value) => {
        if (!value) return false; // Ensure value is not empty

        const dob = new Date(value); // Parse the input date
        const today = new Date(); // Get today's date
        const eighteenYearsAgo = new Date(
          today.getFullYear() - 18,
          today.getMonth(),
          today.getDate()
        );

        return dob <= eighteenYearsAgo; // Check if dob is before or equal to 18 years ago
      }),
    residential_country_code: Yup.string().required("Country code is required"),
    residential_address: Yup.string()
      .required("Address is required")
      .matches(/^[^\s]+(\s+[^\s]+)*/, "Characters must be alphanumaric"),
    residential_city: Yup.string()
      .required("City is required")
      .matches(/^[^\s]+(\s+[^\s]+)*/, "Characters must be alphanumaric"),
    residential_state: Yup.string()
      .required("State is required")
      .matches(/^[^\s]+(\s+[^\s]+)*/, "Characters must be alphanumaric")
      .max(2, "Max length 2 characters, e.g., for California enter 'CA'"),
    residential_postal_code: Yup.string()
      .required("Postal code is required")
      .matches(/^[^\s]+(\s+[^\s]+)*/, "Characters must be alphanumaric"),
    id_type: Yup.string().required("ID type is required"),
    id_number: Yup.string()
      .required("ID number is required")
      .matches(/^[^\s]+(\s+[^\s]+)*/, "Characters must be alphanumaric"),
  }),
});

export const businessFormSchema = Yup.object().shape({
  account_type: Yup.string().required("Account type is required"),
  account_role: Yup.string().required("Account role is required"),
  account_purpose: Yup.string().required("Account purpose is required"),
  first_name: Yup.string()
    .required("First name is required")
    .matches(/^[^\s]+(\s+[^\s]+)*/, "Characters must be alphanumaric"),
  last_name: Yup.string()
    .required("Last name is required")
    .matches(/^[^\s]+(\s+[^\s]+)*/, "Characters must be alphanumaric"),
  email: Yup.string().email("Invalid email").required("Email is required"),
  phone_country_code: Yup.string().required("Country code is required"),
  phone_number: Yup.string()
    .required("Phone number is required")
    .matches(/^[^a-zA-Z]+$/, "Characters must not contain letters"),
  business: Yup.object().shape({
    entity_name: Yup.string()
      .required("Entity name is required")
      .matches(/^[^\s]+(\s+[^\s]+)*/, "Characters must be alphanumaric"),
    incorporation_country: Yup.string()
      .required("Incorporation country is required")
      .matches(/^[^\s]+(\s+[^\s]+)*/, "Characters must be alphanumaric"),
    incorporation_state: Yup.string()
      .required("Incorporation state is required")
      .matches(/^[^\s]+(\s+[^\s]+)*/, "Characters must be alphanumaric")
      .max(2, "Max length 2 characters, e.g., for California enter 'CA'"),
    incorporation_date: Yup.string().required("Incorporation date is required"),
    organization_type: Yup.string().required("Organization type is required"),
    business_type: Yup.string().required("Business type is required"),
    registration_number: Yup.string()
      .required("Registration number is required")
      .matches(/^[^\s]+(\s+[^\s]+)*/, "Characters must be alphanumaric"),
    description: Yup.string()
      .required("Description is required")
      .matches(/^[^\s]+(\s+[^\s]+)*/, "Characters must be alphanumaric"),
    accredited_investor: Yup.boolean().required(
      "Accredited investor status is required"
    ),
    non_agent_intermediary: Yup.boolean().required(
      "Non-agent intermediary status is required"
    ),
    principal_place_country: Yup.string()
      .required("Principal place country is required")
      .matches(/^[^\s]+(\s+[^\s]+)*/, "Characters must be alphanumaric"),
    principal_place_state: Yup.string()
      .required("Principal place state is required")
      .matches(/^[^\s]+(\s+[^\s]+)*/, "Characters must be alphanumaric")
      .max(2, "Max length 2 characters, e.g., for California enter 'CA'"),
    principal_place_address: Yup.string().required(
      "Principal place address is required"
    ),
    principal_place_city: Yup.string()
      .required("Principal place city is required")
      .matches(/^[^\s]+(\s+[^\s]+)*/, "Characters must be alphanumaric"),
    principal_place_postal_code: Yup.string().required(
      "Postal code is required"
    ),
  }),
  beneficial_owners: Yup.array()
    .of(
      Yup.object().shape({
        first_name: Yup.string()
          .required("First name is required")
          .matches(/^[^\s]+(\s+[^\s]+)*/, "Characters must be alphanumaric"),
        middle_name: Yup.string().matches(
          /^[^\s]+(\s+[^\s]+)*/,
          "Characters must be alphanumaric"
        ),
        last_name: Yup.string()
          .required("Last name is required")
          .matches(/^[^\s]+(\s+[^\s]+)*/, "Characters must be alphanumaric"),
        email: Yup.string()
          .email("Invalid email")
          .required("Email is required"),
        dob: Yup.string().required("Date of birth is required"),
        phone_number: Yup.string()
          .required("Phone number is required")
          .matches(/^[^a-zA-Z]+$/, "Characters must not contain letters"),
        id_type: Yup.string().required("ID type is required"),
        residential_country_code: Yup.string().required(
          "ID issuing country is required"
        ),
        address_street1: Yup.string()
          .required("Address is required")
          .matches(/^[^\s]+(\s+[^\s]+)*/, "Characters must be alphanumaric"),
        residential_city: Yup.string()
          .required("City is required")
          .matches(/^[^\s]+(\s+[^\s]+)*/, "Characters must be alphanumaric"),
        residential_state: Yup.string()
          .required("State is required")
          .matches(/^[^\s]+(\s+[^\s]+)*/, "Characters must be alphanumaric")
          .max(2, "Max length 2 characters, e.g., for California enter 'CA'"),
        ssn: Yup.string().required("ID number is required"),
        address_postal_code: Yup.string().required("Postal code is required"),
        // id_number: Yup.string().required("ID number is required"),
      })
    )
    .min(1, "At least one beneficial owner is required"),
});
