import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { businessFormSchema } from "../../validations/schemas";
import {
  accountPurposes,
  businessTypes,
  countryCodes,
  organizationTypes,
} from "../../constants/constants";

const BusinessForm = ({ onSubmit }) => {
  const initialValues = {
    account_type: "business",
    account_role: "",
    account_purpose: "",
    first_name: "",
    last_name: "",
    email: "",
    phone_country_code: "US",
    phone_number: "",

    business: {
      entity_name: "",
      incorporation_country: "US",
      incorporation_state: "",
      incorporation_date: "",
      organization_type: "",
      business_type: "",
      registration_number: "",
      description: "",
      accredited_investor: false,
      non_agent_intermediary: false,
      principal_place_country: "US",
      principal_place_state: "",
      principal_place_address: "",
      principal_place_city: "",
      principal_place_postal_code: "",
    },
    beneficial_owners: [
      {
        first_name: "",
        middle_name: "",
        last_name: "",
        email: "",
        dob: "",
        phone_number: "",
        id_type: "ssn",
        residential_country_code: "US",
        address_street1: "",
        residential_city: "",
        residential_state: "",
        ssn: "",
        address_postal_code: "",
      },
    ],
  };

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={businessFormSchema}
      onSubmit={onSubmit}
    >
      {({ isSubmitting, values }) => (
        <Form noValidate className="space-y-6">
          <div className="space-y-4">
            <h3 className="text-xl font-semibold text-gray-200">
              Account Information
            </h3>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <label className="block text-gray-300">Account Role</label>
                <Field
                  as="select"
                  name="account_role"
                  className="w-full border border-gray-600 rounded-lg px-3 py-2 bg-gray-800 text-gray-300"
                >
                  <option value="">Select Role</option>
                  <option value="client">Client</option>
                  <option value="advisor">Advisor</option>
                </Field>
                <ErrorMessage
                  name="account_role"
                  component="div"
                  className="text-red-500 text-sm"
                />
              </div>

              <div>
                <label className="block text-gray-300">Account Purpose</label>
                <Field
                  as="select"
                  name="account_purpose"
                  className="w-full border border-gray-600 rounded-lg px-3 py-2 bg-gray-800 text-gray-300"
                >
                  <option value="">Select Purpose</option>
                  {accountPurposes.map((purpose) => (
                    <option key={purpose} value={purpose}>
                      {purpose}
                    </option>
                  ))}
                </Field>
                <ErrorMessage
                  name="account_purpose"
                  component="div"
                  className="text-red-500 text-sm"
                />
              </div>
            </div>

            <h3 className="text-xl font-semibold text-gray-200 mt-6">
              Primary Contact
            </h3>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <label className="block text-gray-300">First Name</label>
                <Field
                  name="first_name"
                  className="w-full border border-gray-600 rounded-lg px-3 py-2 bg-gray-800 text-gray-300"
                  type="text"
                />
                <ErrorMessage
                  name="first_name"
                  component="div"
                  className="text-red-500 text-sm"
                />
              </div>

              <div>
                <label className="block text-gray-300">Last Name</label>
                <Field
                  name="last_name"
                  className="w-full border border-gray-600 rounded-lg px-3 py-2 bg-gray-800 text-gray-300"
                  type="text"
                />
                <ErrorMessage
                  name="last_name"
                  component="div"
                  className="text-red-500 text-sm"
                />
              </div>

              <div>
                <label className="block text-gray-300">Email</label>
                <Field
                  name="email"
                  className="w-full border border-gray-600 rounded-lg px-3 py-2 bg-gray-800 text-gray-300"
                  type="email"
                />
                <ErrorMessage
                  name="email"
                  component="div"
                  className="text-red-500 text-sm"
                />
              </div>

              <div>
                <label className="block text-gray-300">
                  Phone Country Code
                </label>
                <Field
                  as="select"
                  name="phone_country_code"
                  className="w-full border border-gray-600 rounded-lg px-3 py-2 bg-gray-800 text-gray-300"
                >
                  <option value="">Phone Country Code</option>
                  {countryCodes.map(({ code, name }) => (
                    <option key={code} value={code}>
                      {name} (+{code})
                    </option>
                  ))}
                </Field>
                <ErrorMessage
                  name="phone_country_code"
                  component="div"
                  className="text-red-500 text-sm"
                />
              </div>

              <div>
                <label className="block text-gray-300">Phone Number</label>
                <Field
                  name="phone_number"
                  className="w-full border border-gray-600 rounded-lg px-3 py-2 bg-gray-800 text-gray-300"
                  type="tel"
                />
                <ErrorMessage
                  name="phone_number"
                  component="div"
                  className="text-red-500 text-sm"
                />
              </div>
            </div>

            <h3 className="text-xl font-semibold text-gray-200 mt-6">
              Business Information
            </h3>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <label className="block text-gray-300">Entity Name</label>
                <Field
                  name="business.entity_name"
                  className="w-full border border-gray-600 rounded-lg px-3 py-2 bg-gray-800 text-gray-300"
                  type="text"
                />
                <ErrorMessage
                  name="business.entity_name"
                  component="div"
                  className="text-red-500 text-sm"
                />
              </div>

              <div>
                <label className="block text-gray-300">
                  Incorporation Country
                </label>
                <Field
                  as="select"
                  name="business.incorporation_country"
                  className="w-full border border-gray-600 rounded-lg px-3 py-2 bg-gray-800 text-gray-300"
                >
                  <option value=""> Incorporation Country Code</option>
                  {countryCodes.map(({ code, name }) => (
                    <option key={code} value={code}>
                      {name} (+{code})
                    </option>
                  ))}
                </Field>
                <ErrorMessage
                  name="business.incorporation_country"
                  component="div"
                  className="text-red-500 text-sm"
                />
              </div>

              <div>
                <label className="block text-gray-300">
                  Incorporation State
                </label>
                <Field
                  name="business.incorporation_state"
                  className="w-full border border-gray-600 rounded-lg px-3 py-2 bg-gray-800 text-gray-300"
                  type="text"
                />
                <ErrorMessage
                  name="business.incorporation_state"
                  component="div"
                  className="text-red-500 text-sm"
                />
              </div>
              <div>
                <label className="block text-gray-300">
                  Incorporation Date
                </label>
                <Field
                  name="business.incorporation_date"
                  className="w-full border border-gray-600 rounded-lg px-3 py-2 bg-gray-800 text-gray-300"
                  type="date"
                  onClick={(e) => e.currentTarget.showPicker()}
                />
                <ErrorMessage
                  name="business.incorporation_date"
                  component="div"
                  className="text-red-500 text-sm"
                />
              </div>

              <div>
                <label className="block text-gray-300">Organization Type</label>
                <Field
                  as="select"
                  name="business.organization_type"
                  className="w-full border border-gray-600 rounded-lg px-3 py-2 bg-gray-800 text-gray-300"
                >
                  <option value="">Select Type</option>
                  {organizationTypes.map((type) => (
                    <option key={type} value={type}>
                      {type}
                    </option>
                  ))}
                </Field>
                <ErrorMessage
                  name="business.organization_type"
                  component="div"
                  className="text-red-500 text-sm"
                />
              </div>

              <div>
                <label className="block text-gray-300">Business Type</label>
                <Field
                  as="select"
                  name="business.business_type"
                  className="w-full border border-gray-600 rounded-lg px-3 py-2 bg-gray-800 text-gray-300"
                >
                  <option value="">Select Type</option>
                  {businessTypes.map((type) => (
                    <option key={type} value={type}>
                      {type}
                    </option>
                  ))}
                </Field>
                <ErrorMessage
                  name="business.business_type"
                  component="div"
                  className="text-red-500 text-sm"
                />
              </div>

              <div>
                <label className="block text-gray-300">
                  Registration Number
                </label>
                <Field
                  name="business.registration_number"
                  className="w-full border border-gray-600 rounded-lg px-3 py-2 bg-gray-800 text-gray-300"
                  type="text"
                />
                <ErrorMessage
                  name="business.registration_number"
                  component="div"
                  className="text-red-500 text-sm"
                />
              </div>

              <div>
                <label className="block text-gray-300">Description</label>
                <Field
                  name="business.description"
                  as="textarea"
                  className="w-full border border-gray-600 rounded-lg px-3 py-2 bg-gray-800 text-gray-300"
                />
                <ErrorMessage
                  name="business.description"
                  component="div"
                  className="text-red-500 text-sm"
                />
              </div>

              <div className="col-span-2">
                <div className="flex space-x-4">
                  <div>
                    <label className="flex items-center space-x-2">
                      <Field
                        type="checkbox"
                        name="business.accredited_investor"
                        className="form-checkbox"
                      />
                      <span className="text-gray-300">Accredited Investor</span>
                    </label>
                  </div>
                  <div>
                    <label className="flex items-center space-x-2">
                      <Field
                        type="checkbox"
                        name="business.non_agent_intermediary"
                        className="form-checkbox"
                      />
                      <span className="text-gray-300">
                        Non-Agent Intermediary
                      </span>
                    </label>
                  </div>
                </div>
              </div>
            </div>

            <h3 className="text-xl font-semibold text-gray-200 mt-6">
              Principal Place of Business
            </h3>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <label className="block text-gray-300">
                  Incorporation Country
                </label>
                <Field
                  as="select"
                  name="business.principal_place_country"
                  className="w-full border border-gray-600 rounded-lg px-3 py-2 bg-gray-800 text-gray-300"
                >
                  <option value=""> Incorporation Country Code</option>
                  {countryCodes.map(({ code, name }) => (
                    <option key={code} value={code}>
                      {name} (+{code})
                    </option>
                  ))}
                </Field>
                <ErrorMessage
                  name="business.principal_place_country"
                  component="div"
                  className="text-red-500 text-sm"
                />
              </div>
              <div>
                <label className="block text-gray-300">State</label>
                <Field
                  name="business.principal_place_state"
                  className="w-full border border-gray-600 rounded-lg px-3 py-2 bg-gray-800 text-gray-300"
                  type="text"
                />
                <ErrorMessage
                  name="business.principal_place_state"
                  component="div"
                  className="text-red-500 text-sm"
                />
              </div>
              <div>
                <label className="block text-gray-300">Address</label>
                <Field
                  name="business.principal_place_address"
                  className="w-full border border-gray-600 rounded-lg px-3 py-2 bg-gray-800 text-gray-300"
                  type="text"
                />
                <ErrorMessage
                  name="business.principal_place_address"
                  component="div"
                  className="text-red-500 text-sm"
                />
              </div>

              <div>
                <label className="block text-gray-300">City</label>
                <Field
                  name="business.principal_place_city"
                  className="w-full border border-gray-600 rounded-lg px-3 py-2 bg-gray-800 text-gray-300"
                  type="text"
                />
                <ErrorMessage
                  name="business.principal_place_city"
                  component="div"
                  className="text-red-500 text-sm"
                />
              </div>

              <div>
                <label className="block text-gray-300">Postal Code</label>
                <Field
                  name="business.principal_place_postal_code"
                  className="w-full border border-gray-600 rounded-lg px-3 py-2 bg-gray-800 text-gray-300"
                  type="text"
                />
                <ErrorMessage
                  name="business.principal_place_postal_code"
                  component="div"
                  className="text-red-500 text-sm"
                />
              </div>
            </div>

            <h3 className="text-xl font-semibold text-gray-200 mt-6">
              Beneficial Owner
            </h3>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <label className="block text-gray-300">First Name</label>
                <Field
                  name="beneficial_owners[0].first_name"
                  className="w-full border border-gray-600 rounded-lg px-3 py-2 bg-gray-800 text-gray-300"
                  type="text"
                />
                <ErrorMessage
                  name="beneficial_owners[0].first_name"
                  component="div"
                  className="text-red-500 text-sm"
                />
              </div>

              <div>
                <label className="block text-gray-300">Middle Name</label>
                <Field
                  name="beneficial_owners[0].middle_name"
                  className="w-full border border-gray-600 rounded-lg px-3 py-2 bg-gray-800 text-gray-300"
                  type="text"
                />
              </div>

              <div>
                <label className="block text-gray-300">Last Name</label>
                <Field
                  name="beneficial_owners[0].last_name"
                  className="w-full border border-gray-600 rounded-lg px-3 py-2 bg-gray-800 text-gray-300"
                  type="text"
                />
                <ErrorMessage
                  name="beneficial_owners[0].last_name"
                  component="div"
                  className="text-red-500 text-sm"
                />
              </div>

              <div>
                <label className="block text-gray-300">Email</label>
                <Field
                  name="beneficial_owners[0].email"
                  className="w-full border border-gray-600 rounded-lg px-3 py-2 bg-gray-800 text-gray-300"
                  type="email"
                />
                <ErrorMessage
                  name="beneficial_owners[0].email"
                  component="div"
                  className="text-red-500 text-sm"
                />
              </div>

              <div>
                <label className="block text-gray-300">Date of Birth</label>
                <Field
                  name="beneficial_owners[0].dob"
                  className="w-full border border-gray-600 rounded-lg px-3 py-2 bg-gray-800 text-gray-300"
                  type="date"
                  onClick={(e) => e.currentTarget.showPicker()}
                />
                <ErrorMessage
                  name="beneficial_owners[0].dob"
                  component="div"
                  className="text-red-500 text-sm"
                />
              </div>

              <div>
                <label className="block text-gray-300">Phone Number</label>
                <Field
                  name="beneficial_owners[0].phone_number"
                  className="w-full border border-gray-600 rounded-lg px-3 py-2 bg-gray-800 text-gray-300"
                  type="tel"
                />
                <ErrorMessage
                  name="beneficial_owners[0].phone_number"
                  component="div"
                  className="text-red-500 text-sm"
                />
              </div>

              <div>
                <label className="block text-gray-300">
                  Address Country Code
                </label>
                <Field
                  as="select"
                  name="beneficial_owners[0].residential_country_code"
                  className="w-full border border-gray-600 rounded-lg px-3 py-2 bg-gray-800 text-gray-300"
                >
                  <option value="">Address Country Code</option>
                  {countryCodes.map(({ code, name }) => (
                    <option key={code} value={code}>
                      {name} (+{code})
                    </option>
                  ))}
                </Field>
                <ErrorMessage
                  name="beneficial_owners[0].residential_country_code"
                  component="div"
                  className="text-red-500 text-sm"
                />
              </div>

              <div>
                <label className="block text-gray-300">Address</label>
                <Field
                  name="beneficial_owners[0].address_street1"
                  className="w-full border border-gray-600 rounded-lg px-3 py-2 bg-gray-800 text-gray-300"
                  type="text"
                />
                <ErrorMessage
                  name="beneficial_owners[0].address_street1"
                  component="div"
                  className="text-red-500 text-sm"
                />
              </div>

              <div>
                <label className="block text-gray-300">City</label>
                <Field
                  name="beneficial_owners[0].residential_city"
                  className="w-full border border-gray-600 rounded-lg px-3 py-2 bg-gray-800 text-gray-300"
                  type="text"
                />
                <ErrorMessage
                  name="beneficial_owners[0].address_city"
                  component="div"
                  className="text-red-500 text-sm"
                />
              </div>

              <div>
                <label className="block text-gray-300">State</label>
                <Field
                  name="beneficial_owners[0].residential_state"
                  className="w-full border border-gray-600 rounded-lg px-3 py-2 bg-gray-800 text-gray-300"
                  type="text"
                />
                <ErrorMessage
                  name="beneficial_owners[0].residential_state"
                  component="div"
                  className="text-red-500 text-sm"
                />
              </div>

              <div>
                <label className="block text-gray-300">
                  Address Postal Code
                </label>
                <Field
                  name="beneficial_owners[0].address_postal_code"
                  className="w-full border border-gray-600 rounded-lg px-3 py-2 bg-gray-800 text-gray-300"
                  type="text"
                />
                <ErrorMessage
                  name="beneficial_owners[0].address_postal_code"
                  component="div"
                  className="text-red-500 text-sm"
                />
              </div>

              <div>
                <label className="block text-gray-300">ID Type</label>
                <Field
                  as="select"
                  name="beneficial_owners[0].id_type"
                  className="w-full border border-gray-600 rounded-lg px-3 py-2 bg-gray-800 text-gray-300"
                >
                  <option value="">Select ID Type</option>
                  {values?.beneficial_owners[0]?.residential_country_code ===
                  "US" ? (
                    <>
                      <option value="passport">Passport</option>
                    </>
                  ) : (
                    <>
                      <option value="passport">Passport</option>
                      <option value="ssn">SSN</option>
                    </>
                  )}
                </Field>
                <ErrorMessage
                  name="beneficial_owners[0].id_type"
                  component="div"
                  className="text-red-500 text-sm"
                />
              </div>

              <div>
                <label className="block text-gray-300">ID Number</label>
                <Field
                  name="beneficial_owners[0].ssn"
                  className="w-full border border-gray-600 rounded-lg px-3 py-2 bg-gray-800 text-gray-300"
                  type="text"
                />
                <ErrorMessage
                  name="beneficial_owners[0].ssn"
                  component="div"
                  className="text-red-500 text-sm"
                />
              </div>
            </div>
          </div>

          <div className="mt-6">
            <button
              type="submit"
              className={`w-full ${
                isSubmitting
                  ? "bg-yellow-900"
                  : "bg-[#b5924e]  hover:bg-yellow-600"
              } text-white py-2 rounded-lg transition-colors disabled:opacity-50 disabled:cursor-not-allowed`}
            >
              {isSubmitting ? "Submitting..." : "Submit"}
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default BusinessForm;
