import { postRequest } from "../interface/axiosMethods";

async function createIndividual(payload) {
  try {
    const response = await postRequest("user/individual", payload);
    return await response;
  } catch (error) {
    return await error;
  }
}
async function createBusiness(payload) {
  try {
    const response = await postRequest("user/business", payload);
    return await response;
  } catch (error) {
    return await error;
  }
}
async function verifyEmail(email, code) {
  let payload = {
    email: email,
    otp: code,
  };
  try {
    const response = await postRequest("user/verifyEmail", payload);
    return await response;
  } catch (error) {
    return await error;
  }
}
async function verifySMS(email, code) {
  let payload = {
    email: email,
    otp: code,
  };
  try {
    const response = await postRequest("user/verifyPhone", payload);
    return await response;
  } catch (error) {
    return await error;
  }
}

async function getUserStatus(email) {
  let payload = {
    email: email,
  };
  try {
    const response = await postRequest("user/verifyUser", payload);
    return await response;
  } catch (error) {
    return await error;
  }
}

async function onboardUserIndividual(transformedData, email) {
  // Convert JSON to FormData
  let formData = new FormData();

  Object.keys(transformedData).forEach((key) => {
    const response = transformedData[key];
    if (typeof response === "object" && !Array.isArray(response)) {
      // Handle nested objects
      Object.keys(response).forEach((nestedKey) => {
        formData.append(`${nestedKey}`, response[nestedKey].response);
      });
    } else {
      // Handle simple key-value pairs
      formData.append(`${key}`, response);
    }
  });
  formData.append("email", email);
  try {
    const response = await postRequest("user/onboardUserIndividual", formData);
    return await response;
  } catch (error) {
    return await error;
  }
}
export {
  createIndividual,
  createBusiness,
  verifyEmail,
  verifySMS,
  getUserStatus,
  onboardUserIndividual,
};
