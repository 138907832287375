import axiosClient from "./axiosConfig";

export function getRequest(URL, config = {}) {
  return axiosClient.get(`/${URL}`, config).then((response) => response);
}

export function postRequest(URL, payload, config = {}) {
  return axiosClient
    .post(`/${URL}`, payload, config)
    .then((response) => response);
}

export function patchRequest(URL, payload) {
  return axiosClient.patch(`/${URL}`, payload).then((response) => response);
}

export function putRequest(URL, payload) {
  return axiosClient.put(`/${URL}`, payload).then((response) => response);
}

export function deleteRequest(URL, payload) {
  return axiosClient
    .delete(`/${URL}`, payload && { data: payload })
    .then((response) => response);
}
