import { atom } from "recoil";
import { recoilPersist } from "recoil-persist";

// keep state presistence when refreshing browser store in session storage
const { persistAtom } = recoilPersist({
  key: "state",
  storage: sessionStorage,
  converter: JSON,
});

//  state for common loader component
export const loaderState = atom({
  key: "loader",
  default: false,
  effects_UNSTABLE: [persistAtom],
});
export const userState = atom({
  key: "userData",
  default: false,
  effects_UNSTABLE: [persistAtom],
});
