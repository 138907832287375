import React from "react";
import FormContainerLogin from "../components/layout/FormContainerLogin";

const Sign = () => {
  return (
    <div className="container mx-auto flex flex-col items-center">
      <h1 className="text-3xl font-bold text-white mb-8">Login</h1>
      {/* Add your sign page content here */}
      <FormContainerLogin />
    </div>
  );
};

export default Sign;
