import { useRecoilValue } from "recoil";
import { loaderState } from "../atoms/atoms";

export default function Loader() {
  const loader = useRecoilValue(loaderState);

  return (
    <>
      <div
        className="sweet-loading"
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          zIndex: 99,
          width: "100vw",
          height: "100vh",
          backgroundColor: "#0003",
          opacity: 1,
          display: loader ? "flex" : "none",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {loader && (
          <svg viewBox="25 25 50 50" className="loader">
            <circle cx="50" cy="50" r="20" class="loader"></circle>
          </svg>
        )}
      </div>
    </>
  );
}
