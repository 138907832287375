import React from "react";
import Logo from "../../assets/images/logo.png";
import { Link } from "react-router-dom";

const Header = () => {
  return (
    <header className="bg-gray-800 p-4 shadow-xl">
      <div className="container mx-auto flex justify-between items-center">
        <nav>
          <Link to="/" className="text-white hover:text-green-500 mx-2">
            <img className="max-w-[180px]" src={Logo} alt="logo" />
          </Link>
        </nav>
      </div>
    </header>
  );
};

export default Header;
