import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { individualFormSchema } from "../../validations/schemas";
import { countryCodes } from "../../constants/constants";

const IndividualForm = ({ onSubmit }) => {
  const initialValues = {
    account_type: "individual",
    account_role: "",
    account_purpose: "",
    first_name: "",
    last_name: "",
    email: "",
    phone_country_code: "US",
    phone_number: "",
    individual: {
      dob: "",
      residential_country_code: "US",
      residential_address: "",
      residential_city: "",
      residential_state: "",
      residential_postal_code: "",
      id_type: "",
      id_number: "",
    },
  };

  const accountPurposes = [
    "Trading",
    "Selling only",
    "Other",
    "Online purchases",
    "Online payments",
    "Investing",
    "Buying only",
    "Business",
  ];

  return (
    <Formik
      noValidate
      enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={individualFormSchema}
      onSubmit={onSubmit}
    >
      {({ isSubmitting, values }) => (
        <Form className="w-full max-w-6xl mx-auto space-y-4">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
              <label className="block text-gray-300">Account Role</label>
              <Field
                as="select"
                name="account_role"
                className="w-full border border-gray-600 rounded-lg px-3 py-2 bg-gray-800 text-gray-300"
              >
                <option value="">Select Role</option>
                <option value="client">Client</option>
                <option value="advisor">Advisor</option>
              </Field>
              <ErrorMessage
                name="account_role"
                component="div"
                className="text-red-500 text-sm"
              />
            </div>

            <div>
              <label className="block text-gray-300">Account Purpose</label>
              <Field
                as="select"
                name="account_purpose"
                className="w-full border border-gray-600 rounded-lg px-3 py-2 bg-gray-800 text-gray-300"
              >
                <option value="">Select Purpose</option>
                {accountPurposes.map((purpose) => (
                  <option key={purpose} value={purpose}>
                    {purpose}
                  </option>
                ))}
              </Field>
              <ErrorMessage
                name="account_purpose"
                component="div"
                className="text-red-500 text-sm"
              />
            </div>

            <div>
              <label className="block text-gray-300">First Name</label>
              <Field
                placeholder="John"
                name="first_name"
                type="text"
                className="w-full border border-gray-600 rounded-lg px-3 py-2 bg-gray-800 text-gray-300"
              />
              <ErrorMessage
                name="first_name"
                component="div"
                className="text-red-500 text-sm"
              />
            </div>

            <div>
              <label className="block text-gray-300">Last Name</label>
              <Field
                name="last_name"
                type="text"
                className="w-full border border-gray-600 rounded-lg px-3 py-2 bg-gray-800 text-gray-300"
                placeholder="doe"
              />
              <ErrorMessage
                name="last_name"
                component="div"
                className="text-red-500 text-sm"
              />
            </div>

            <div>
              <label className="block text-gray-300">Email</label>
              <Field
                name="email"
                type="email"
                className="w-full border border-gray-600 rounded-lg px-3 py-2 bg-gray-800 text-gray-300"
                placeholder="johndoe@example.com"
              />
              <ErrorMessage
                name="email"
                component="div"
                className="text-red-500 text-sm"
              />
            </div>
            <div>
              <label className="block text-gray-300">Phone Country Code</label>
              <Field
                as="select"
                name="phone_country_code"
                className="w-full border border-gray-600 rounded-lg px-3 py-2 bg-gray-800 text-gray-300"
              >
                <option value="">Select Country Code</option>
                {countryCodes.map(({ code, name }) => (
                  <option key={code} value={code}>
                    {name} (+{code})
                  </option>
                ))}
              </Field>
              <ErrorMessage
                name="phone_country_code"
                component="div"
                className="text-red-500 text-sm"
              />
            </div>

            <div>
              <label className="block text-gray-300">Phone Number</label>
              <Field
                name="phone_number"
                type="tel"
                className="w-full border border-gray-600 rounded-lg px-3 py-2 bg-gray-800 text-gray-300"
                placeholder="0000000000"
              />
              <ErrorMessage
                name="phone_number"
                component="div"
                className="text-red-500 text-sm"
              />
            </div>

            <div>
              <label className="block text-gray-300">Date of Birth</label>
              <Field
                name="individual.dob"
                type="date"
                className="w-full border border-gray-600 rounded-lg px-3 py-2 bg-gray-800 text-gray-300"
                id="dob"
                onClick={(e) => e.currentTarget.showPicker()}
              />
              <ErrorMessage
                name="individual.dob"
                component="div"
                className="text-red-500 text-sm"
              />
            </div>

            <div>
              <label className="block text-gray-300">
                Residential Country Code
              </label>
              <Field
                as="select"
                name="individual.residential_country_code"
                className="w-full border border-gray-600 rounded-lg px-3 py-2 bg-gray-800 text-gray-300"
              >
                <option value="">Residential Country Code</option>
                {countryCodes.map(({ code, name }) => (
                  <option key={code} value={code}>
                    {name} (+{code})
                  </option>
                ))}
              </Field>
              <ErrorMessage
                name="individual.residential_country_code"
                component="div"
                className="text-red-500 text-sm"
              />
            </div>

            <div>
              <label className="block text-gray-300">Residential Address</label>
              <Field
                name="individual.residential_address"
                type="text"
                className="w-full border border-gray-600 rounded-lg px-3 py-2 bg-gray-800 text-gray-300"
                placeholder="123 Main St"
              />
              <ErrorMessage
                name="individual.residential_address"
                component="div"
                className="text-red-500 text-sm"
              />
            </div>

            <div>
              <label className="block text-gray-300">City</label>
              <Field
                name="individual.residential_city"
                type="text"
                className="w-full border border-gray-600 rounded-lg px-3 py-2 bg-gray-800 text-gray-300"
                placeholder="New York"
              />
              <ErrorMessage
                name="individual.residential_city"
                component="div"
                className="text-red-500 text-sm"
              />
            </div>

            <div>
              <label className="block text-gray-300">State</label>
              <Field
                name="individual.residential_state"
                type="text"
                className="w-full border border-gray-600 rounded-lg px-3 py-2 bg-gray-800 text-gray-300"
                placeholder="NY"
              />
              <ErrorMessage
                name="individual.residential_state"
                component="div"
                className="text-red-500 text-sm"
              />
            </div>

            <div>
              <label className="block text-gray-300">Postal Code</label>
              <Field
                name="individual.residential_postal_code"
                type="text"
                className="w-full border border-gray-600 rounded-lg px-3 py-2 bg-gray-800 text-gray-300"
                placeholder="10001"
              />
              <ErrorMessage
                name="individual.residential_postal_code"
                component="div"
                className="text-red-500 text-sm"
              />
            </div>

            <div>
              <label className="block text-gray-300">ID Type</label>
              <Field
                as="select"
                name="individual.id_type"
                className="w-full border border-gray-600 rounded-lg px-3 py-2 bg-gray-800 text-gray-300"
              >
                <option value="">Select ID Type</option>
                {values?.individual?.residential_country_code === "US" ? (
                  <>
                    <option value="passport">Passport</option>
                  </>
                ) : (
                  <>
                    <option value="passport">Passport</option>
                    <option value="ssn">SSN</option>
                  </>
                )}
              </Field>
              <ErrorMessage
                name="individual.id_type"
                component="div"
                className="text-red-500 text-sm"
              />
            </div>

            <div>
              <label className="block text-gray-300">ID Number</label>
              <Field
                name="individual.id_number"
                type="text"
                className="w-full border border-gray-600 rounded-lg px-3 py-2 bg-gray-800 text-gray-300"
                placeholder="123456789"
              />
              <ErrorMessage
                name="individual.id_number"
                component="div"
                className="text-red-500 text-sm"
              />
            </div>
          </div>

          <div className="mt-6">
            <button
              type="submit"
              className={`w-full ${
                isSubmitting ? "bg-yellow-900" : "bg-[#b5924e] "
              } text-white py-2 hover:bg-yellow-600 rounded-lg transition-colors disabled:opacity-50 disabled:cursor-not-allowed`}
            >
              {isSubmitting ? "Submitting..." : "Submit"}
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default IndividualForm;
